import React, { useEffect } from 'react';
import { authActions } from 'application/store';
import { AppStore } from 'domain/types/store';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/theme.scss';
import { loadSavedItems } from 'application/store/slices/shoppingCart';
import Routes from './views/pages/routes';
import { useSearchParams } from 'react-router-dom';
import { AuthRepository } from 'application/repositories';

function App() {
  const user = useSelector((state: AppStore) => state.auth.user);
  const [params] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      dispatch(authActions.logInFromStorage());
    }
    dispatch(loadSavedItems());
  }, []);

  useEffect(() => {
    const token = params.get("token");
    if(token) {
      const repository = new AuthRepository();
      repository.setToken(token);
    }
  }, [params]);


  return (
    <>
      <ToastContainer autoClose={5000} />
      <Routes />
    </>
  );
}

export default App;
