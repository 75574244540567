import React, { ChangeEvent, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import './index.css';
import { ShoppingCartItem as IShoppingCartItem, RateItemCart } from 'domain/types/store';
import { calculateRate } from 'application/utils/shoppingCart';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

interface RateProps {
  item: IShoppingCartItem;
  // eslint-disable-next-line no-unused-vars
  onUpdate(i: IShoppingCartItem): void;
}

function Rate({ item, onUpdate }: RateProps) {
  const ref = useRef<HTMLSelectElement>(null);
  const childRef = useRef<HTMLSelectElement>(null);
  const adultsRef = useRef<HTMLSelectElement>(null);

  const rate = item.item as RateItemCart;

  const recalculatePrice = () => {
    const amount = parseInt(ref.current?.value || '1', 10);
    const adults = parseInt(adultsRef.current!.value, 10);
    const childrens = parseInt(childRef.current!.value, 10);
    const newRate = { ...rate, childrens, adults };
    const total = calculateRate(newRate, amount);

    onUpdate({
      ...item, item: newRate, amount, total,
    });
  };

  const handleMonthsChanges = (event: ChangeEvent<HTMLSelectElement>) => {
    const months = parseInt(event.target.value, 10);
    const value = { ...item, qty_fee: months };
    onUpdate(value);
  };

  useEffect(() => {
    childRef.current!.value = (rate?.childrens|| "").toString();
    adultsRef.current!.value = (rate?.adults || "").toString();
    ref.current!.value = item.amount.toString();
  }, []);

  const styleSelectObject = {
    width: '15%',
  };

  return (
    <div>
      <div className="d-flex align-items-center my-4 d-flex ">
        <div className="d-flex align-items-center">
        <span>{t("packages.detaial.rate.adults")}:</span>
          {' '}
          <select
            className="form-select custom-select col-6 ml-1 w-50 mx-1"
            ref={adultsRef}
            onChange={recalculatePrice}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
          &nbsp;
        </div>
        <div className="d-flex align-items-center">
          <span>&nbsp;{t("packages.detaial.rate.children")}:&nbsp;</span>
          {' '}
          <select
            className="form-select custom-select col-6 ml-1 w-50"
            style={styleSelectObject}
            ref={childRef}
            onChange={recalculatePrice}
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
        <div className="d-flex align-items-center">
        &nbsp;{t("packages.detaial.rate.nigth")}:&nbsp;
          {'  '}
          <select
            className="form-select custom-select col-6 ml-1 w-50"
            ref={ref}
            onChange={recalculatePrice}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
      </div>
      <div className="d-flex align-items-center my-4 w-50">
        <span>{t("packages.detaial.package.quotas")}:</span>
        {' '}
        <select
            className="form-select custom-select mx-1 form-select custom-select"
            style={styleSelectObject}
            value={item.qty_fee}
            onChange={handleMonthsChanges}
          >
            {
              [...Array(item.item.qty_fee).keys()].map(item=> <option key={`${item}_option`} value={item + 1}>{item + 1}</option>)
            }
          </select>
      </div>
    </div>
  );
}

function Package(props: RateProps) {
  const { item, onUpdate } = props;
  const {t} = useTranslation();

  const handleAmountChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const amount = parseInt(event.target.value, 10);
    const value = { ...item, amount, total: amount * item.item.price };
    onUpdate(value);
  };

  const handleMonthsChanges = (event: ChangeEvent<HTMLSelectElement>) => {
    const months = parseInt(event.target.value, 10);
    const value = { ...item, qty_fee: months };
    onUpdate(value);
  };

  const styleSelectObject = {
    width: '15%',
  };

  return (
    <div>
      <div className="d-flex align-items-center my-4 w-50">
      <span>{t("packages.detaial.package.items")}:</span>
      
        <select
          className="form-select custom-select mx-1 form-select custom-select"
          value={item.amount}
          onChange={handleAmountChange}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </div>
      <div className="d-flex align-items-center my-4 w-50">
        <span>{t("packages.detaial.package.quotas")}:</span>
          {' '}
        <select
            className="form-select custom-select mx-1 form-select custom-select"
            style={styleSelectObject}
            value={item.qty_fee}
            onChange={handleMonthsChanges}
          >
            {
              [...Array(item.item.qty_fee).keys()].map(item=> <option key={`${item}_option`} value={item + 1}>{item + 1}</option>)
            }
        </select>
      </div>
    </div>
  );
}

export interface ShoppingCartItemProps {
  item: IShoppingCartItem;
  onDelete(): void;
    // eslint-disable-next-line no-unused-vars
  onUpdate(item: IShoppingCartItem): void;
}

export function ShoppingCartItem(props: ShoppingCartItemProps) {
  const { t } = useTranslation();
  const {
    onDelete, onUpdate, item,
  } = props;

  return (
    <div className="card well rounded">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <h5>{item.item.title}</h5>
          <button type="button" className="btn btn-outline-danger btn-circle" onClick={onDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
        {
          item.item.package_type === 2 ? (<Rate item={item} onUpdate={onUpdate} />)
            : (<Package item={item} onUpdate={onUpdate} />)
        }
        <div className="mt-2">
          <p className="cart-text text-muted">
            {t("packages.detaial.package.price")}:
            {' '}
            <span className="text-success">
              $
              {item.total}
            </span>
          </p>
        </div>
        <div className="mt-2">
          <p className="cart-text text-muted">
            {t("packages.detaial.package.price_by_month")}:
            {' '}
            <span className="text-success">
              $
              {Number((item.total/ (item?.qty_fee || 0)).toFixed(2))}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
