/* eslint-disable class-methods-use-this */
import { httpClient } from 'application/utils/http-client';
import { AuthDto, AuthUserDto } from 'core/dtos/auth';

export class AuthRepository {
  readonly TOKEN_KEY = '__token__';

  readonly REFRESH_KEY = '__refresh__';

  readonly USER_KEY = '__user__';

  getToken(): string|null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.REFRESH_KEY);
  }

  isLogged(): boolean {
    const token = this.getToken();
    return !!token;
  }

  logOut = () => {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.REFRESH_KEY);
    localStorage.removeItem(this.USER_KEY);
  };

  async login(username: string, password: string) {
    const response = await httpClient.post('/auth/login', { username, password });
    if (response.status === 200 || response.status === 201) {
      const data = response.data as AuthDto;
      this.setToken(data.token);
      localStorage.setItem(this.REFRESH_KEY, data.refreshToken);
      localStorage.setItem(this.USER_KEY, JSON.stringify(data.user_data));
    }

    return response;
  }

  setToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  async create(data: any) {
    const response = await httpClient.post('/user', {
      ...data,
      country_id: 1,
      status: 1,
      account_type: 'general',
      full_name: `${data.first_name} ${data.last_name}`,
    });

    return response;
  }

  getStoreduser(): AuthUserDto | undefined {
    const rawString = localStorage.getItem(this.USER_KEY);

    if (rawString) {
      return JSON.parse(rawString) as AuthUserDto;
    }
    return undefined;
  }
}
