/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Alert,
  Card, CardBody, FormFeedback, Input, Label, //  Alert, Col,
} from 'reactstrap';

import { AuthRepository } from 'application/repositories';
import { useTranslation } from 'react-i18next';

export interface Auth {
  first_name: string;
  last_name: string;
  gender: number;
  email: string;
  birthdate: string;
  phone: string;
  identification: string;
  password: string;
  password_confirm: string;
}

export interface RegisterFormProps {
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onUserRegistered(info: Auth): void;
}

export function RegisterForm(props: RegisterFormProps) {
  const {
    handleSubmit, formState: { errors }, control, watch,
  } = useForm<Auth>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const password = useRef({});
  const {t} = useTranslation();
  password.current = watch('password', '');

  const { className, onUserRegistered } = props;

  const handleLogin = async (data: Auth) => {
    setIsLoading(true);
    setError('');
    const service = new AuthRepository();

    try {
      const response = await service.create(data);
      if (response?.status === 201) {
        onUserRegistered(data);
        setIsLoading(false);
      } else {
        setError(response.data.toString());
      }
    } catch (exception: any) {
      setError(exception.response?.data?.message);
    }
    setIsLoading(false);
  };

  return (
    <Card className={className}>

      <CardBody className="pt-0">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div>
            <h4>{t("signup")}</h4>
          </div>

          <div className="row mb-3">
            <div className="col-lg-6">
              <Controller
                name="first_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Label className="form-label">{t("signup.name")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("signup.name")}
                      invalid={!!errors.first_name}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                    {errors.first_name && errors.first_name?.type === 'required' ? (
                      <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="col-lg-6">
              <Controller
                name="last_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Label className="form-label">{t("signup.last_name")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("signup.last_name")}
                      invalid={!!errors.last_name}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                    {errors.last_name && errors.last_name?.type === 'required' ? (
                      <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: true,
                  validate: (value: number) => !!value,
                }}
                render={({ field }) => (
                  <>
                    <Label className="form-label">{t("signup.gender")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("signup.gender")}
                      type="select"
                      invalid={!!errors.gender}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    >
                      <option value="">{t("signup.gender.select")}</option>
                      <option value="1">{t("signup.gender.male")}</option>
                      <option value="2">{t("signup.gender.female")}</option>
                    </Input>
                    {errors.gender && errors.gender?.type === 'required' ? (
                      <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="col-lg-6">
              <Controller
                name="birthdate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Label className="form-label">{t("signup.birth_day")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("signup.birth_day")}
                      type="date"
                      invalid={!!errors.birthdate}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                    {errors.birthdate && errors.birthdate?.type === 'required' ? (
                      <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Label className="form-label">{t("signup.email")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("signup.email")}
                      type="email"
                      invalid={!!errors.email}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                    {errors.email && errors.email?.type === 'required' ? (
                      <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <Controller
                name="phone"
                control={control}
                rules={{ required: true, maxLength: 8 }}
                render={({ field }) => (
                  <>
                    <Label className="form-label">{t("signup.phone")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("signup.phone")}
                      invalid={!!errors.phone}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                    {errors.phone && errors.phone?.type === 'required' ? (
                      <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                    ) : null}
                    {errors.phone && errors.phone?.type === 'maxLength' ? (
                      <FormFeedback type="invalid">{t("form.maxLeng.field")} &nbsp; 8</FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="col-lg-6">
              <Controller
                name="identification"
                control={control}
                rules={{ required: true, maxLength: 20 }}
                render={({ field }) => (
                  <>
                    <Label className="form-label">{t("signup.doc_id")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("signup.doc_id")}
                      invalid={!!errors.identification}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                    {errors.identification && errors.identification?.type === 'required' ? (
                      <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                    ) : null}
                    {errors.identification && errors.identification?.type === 'maxLength' ? (
                      <FormFeedback type="invalid">{t("form.maxLeng.field")} &nbsp;20</FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <Controller
                name="password"
                control={control}
                rules={{ required: true, minLength: 8, pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*)/ }}
                render={({ field }) => (
                  <>
                    <Label className="form-label">{t("signup.password")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("signup.password")}
                      invalid={!!errors.password}
                      type="password"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                    {errors.password && errors.password?.type === 'required' ? (
                      <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                    ) : null}
                    {errors.password && errors.password?.type === 'minLength' ? (
                      <FormFeedback type="invalid">{t("form.minLeng.field")} &nbsp; 8</FormFeedback>
                    ) : null}
                    {errors.password && errors.password?.type === 'pattern' ? (
                      <FormFeedback type="invalid">{t("form.pattern.password")}</FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div className="col-12 mt-3">
              <Controller
                name="password_confirm"
                control={control}
                rules={{ required: true, validate: (value: string) => value === password.current }}
                render={({ field }) => (
                  <>
                    <Label className="form-label">{t("signup.confirm.password")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("signup.confirm.password")}
                      invalid={!!errors.password_confirm}
                      type="password"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                    {errors.password_confirm && errors.password_confirm?.type === 'required' ? (
                      <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                    ) : null}
                    {errors.password_confirm && errors.password_confirm?.type === 'validate' ? (
                      <FormFeedback type="invalid">{t("signup.confirm.didnt.match")}</FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>
          <div className="mt-3 d-grid">
            <button
              disabled={isLoading}
              className="btn btn-success btn-block text-white"
              type="submit"
            >
              {t("signup.btn")}
            </button>
          </div>
          {
            error ? (
              <div className="mt-3 d-grid">
                <Alert color="danger" fade>
                  <span className="text-white">{error}</span>
                </Alert>
              </div>
            ) : null
          }
        </form>
      </CardBody>
    </Card>
  );
}

RegisterForm.defaultProps = {
  className: 'shadow',
};
