import React, { lazy, Suspense } from 'react';
import { Routes as Switch, Route } from 'react-router-dom';
import MainPage from './main';

const Login = lazy(() => import('./auth/login'));
const ChangePaymentMethod = lazy(() => import('./shoppingCart/Payment/containers/changePaymentMethod'));

export const routeMap = {
  login: '/login',
  root: '/*',
  home: '/home',
  rates: '/rates',
  shoppingCart: '/shopping',
  changePaymentMethod: '/change-payment-method/:userId/:orderId'
};

export default function Routes() {
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Switch>
        <Route path={routeMap.login} element={<Login />} />
        <Route path={routeMap.changePaymentMethod} element={<ChangePaymentMethod />} />
        <Route path={routeMap.root} element={<MainPage />} />
      </Switch>
    </Suspense>
  );
}
