import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth';
import uiSlice from './slices/ui';
import shoppingCartSlice from './slices/shoppingCart';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    shoppingCart: shoppingCartSlice.reducer,
  },
});

export default store;
