import { AxiosResponse } from "axios";
import { httpClient } from "application/utils/http-client";

export class PaymentMethodRepository {
  endpoint = "/payment-method";

  paymentEndpoint = "/payment";

  async list(params: any): Promise<AxiosResponse> {
    const response = await httpClient.get(`${this.endpoint}`, { params });

    return response;
  }

  async register(data: any): Promise<AxiosResponse> {
    const response = await httpClient.post(`${this.endpoint}`, {
      paymentMethod: data,
    });

    return response;
  }

  async pay(data: any): Promise<AxiosResponse> {
    const response = await httpClient.post(this.paymentEndpoint, data);

    return response;
  }

  async updatePaymentMethod(payload: any) {
    return await httpClient.put(
      `${this.endpoint}/update-payment-method-of-order-detail`,
      payload
    );
  }
}

export default PaymentMethodRepository;
