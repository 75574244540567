import { TripType } from "core/entities";
import { RateItemCart, ShoppingCartItem } from "domain/types/store";

export const calculateRate = (rate: RateItemCart, amount: number) => {
    const rateValue =
        parseInt(amount.toString(), 10) *
        (parseInt(rate.childrens.toString(), 10) *
            parseFloat((rate.children_price || 0).toString()) +
            parseInt(rate.adults.toString(), 10) *
                parseFloat((rate.adult_price || 0).toString()));

    return rateValue;
};

export const calculateTotal = (items: ShoppingCartItem[]) => {
    let total = 0;

    const packages = items.filter(
        (item) => item.item.package_type === TripType.package
    );
    const rates = items.filter(
        (item) => item.item.package_type === TripType.rate
    );

    packages.forEach((pkg) => {
        total +=
            parseFloat(pkg.item.price.toString()) *
            parseInt(pkg.amount.toString(), 10);
    });

    rates.forEach((row) => {
        const rate = row.item as RateItemCart;
        const rateValue = calculateRate(rate, row.amount);

        total += rateValue;
    });

    return total;
};
