import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardBody, Card, Modal } from 'reactstrap';
import './index.css';

export interface CardProps {
    title: string;
    imageUrl: string;
    description: string;
    price: number;
    // eslint-disable-next-line no-unused-vars
    onClick(): void;
}

export function ItemCard(props: CardProps) {
  const { t } = useTranslation();

  const {
    title, imageUrl, description, price, onClick,
  } = props;
  
  const url = imageUrl || '/assets/no_image.jpeg';
  return (
    <Card
      onClick={onClick}
    >
      <CardBody>
        <div className="img-card position-relative">
          <img            
            src={url}
            alt=""
            className="img-fluid mx-auto d-block package-image"
            onError={addDefaultSrc}
          />          
        </div>
        <div className="mt-4 pointer">
          <h4 className="mb-3 text-truncate text-dark">
            <strong>{title}</strong>
          </h4>
          <h6>
            {description.substring(0, 120)}
          </h6>
          $
          {price}
        </div>
        <div className="mt-2">
          <button type="button" className="btn btn-outline-danger">{t("buy")}</button>
        </div>
      </CardBody>
    </Card>
  );  
}

export default Card;

function addDefaultSrc(ev: any) {
  ev.target.src = '/assets/no_image.jpeg';
}

