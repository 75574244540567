/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { ShoppingCartItem } from 'domain/types/store';
import { Input, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface ShoppingCartResumeProps {
  items: ShoppingCartItem[];
  onComplete(): void;
  disabled: boolean;
  total: number;
  fee: number;
  loading?: boolean;
  onAcceptTerms?(accepted: boolean): void;
}

export function ShoppingCartResume({
  items, onComplete, disabled, total, loading, fee, onAcceptTerms,
}: ShoppingCartResumeProps) {

  const { t } = useTranslation();

  return (
    <div className="card p-3 well">
      <div className="card-body">
        <h4 className="card-title">{t("shoppingCart.order_resume")}</h4>
        <div className="row">
          {
            items.map((item: ShoppingCartItem) => (
              <React.Fragment key={item.item.id}>
                <p className="card-text col-6">
                  {item.item.title}
                  :
                </p>
                <p className="col-6">
                  $
                  {item.total.toFixed(2)}
                </p>
              </React.Fragment>
            ))
          }
        </div>
        <div className="row">
          <p className="card-text col-6">
            Total:
          </p>
          <p className="col-6">
            {' '}
            $
            {(total - fee).toFixed(2)} IVI
          </p>
        </div>
        {
          onAcceptTerms ? (
            <div className="py-2">
              <Input type="checkbox" onChange={(e: ChangeEvent<HTMLInputElement>) => onAcceptTerms(e.target.checked)} />
              {' '}
              <strong>
                {t("accept.terms_and_conditions")}
              </strong>
            </div>
          ) : null
        }
        <div>
          <button
            type="button"
            className="btn btn-primary btn-lg text-white rounded"
            disabled={disabled}
            onClick={onComplete}
          >
            {
              loading ? (<Spinner animation="border" role="status" size="sm" />) : null
            }
            &nbsp;
            {t("shoppingCart.apply_payment")}
            {' '}
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
    </div>
  );
}

ShoppingCartResume.defaultProps = {
  loading: false,
};
