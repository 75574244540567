import { AxiosResponse } from "axios";
import { httpClient } from "application/utils/http-client";
import { Trip } from "core/entities";

export interface PaginatedResponse {
    result: Trip[];
    count: number;
    page: number;
    pageSize: number;
}

export class TripRepository {
    endpoint = "/package";

    async list(params: any): Promise<AxiosResponse<PaginatedResponse>> {
        const response = await httpClient.get<PaginatedResponse>(
            `${this.endpoint}/paging`,
            { params }
        );

        return response;
    }

    async get(id: string | number): Promise<AxiosResponse<Trip | undefined>> {
        const response = await httpClient.get<Trip | undefined>(
            `${this.endpoint}/${id}`
        );

        return response;
    }
}

export default TripRepository;
