/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import OrderRepository from "application/repositories/order";
import PaymentMethodRepository from "application/repositories/paymentMethods";
import { ShoppingCartRepository } from "application/repositories/shoppingcart";
import { calculateTotal } from "application/utils/shoppingCart";
import { ShoppingCartItem, ShoppingCartStore } from "domain/types/store";
import { Dispatch } from "react";
import { toast } from "react-toastify";

import i18n from "../../../i18n";

const initialState: ShoppingCartStore = {
  items: [],
  paymentMethods: [],
  cashPayment: true,
  qoutas: 0,
  saving: false,
  total: 0,
  fee: 0,
  transaction: null,
};

const getTotal = (items: any[]) => {
  const total = calculateTotal(items);
  const fee = total * 0.16;
  return { total: total + fee, fee };
};

const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    addItem: (state: ShoppingCartStore, action) => {
      state.items = [...state.items, action.payload];
      const values = getTotal(state.items);
      state.total = values.total;
      state.fee = values.fee;
    },
    removeItem: (state: ShoppingCartStore, action) => {
      state.items = state.items.filter((_, index) => index !== action.payload);
      const values = getTotal(state.items);
      state.total = values.total;
      state.fee = values.fee;
    },
    updateItem: (state: ShoppingCartStore, action) => {
      const { itemIndex, amount } = action.payload;
      const item = state.items.find((_, index) => index === itemIndex);
      item!.amount = amount;
      const values = getTotal(state.items);
      state.total = values.total;
      state.fee = values.fee;
    },
    setItems: (state: ShoppingCartStore, action) => {
      state.items = action.payload;
      const values = getTotal(action.payload);
      state.total = values.total;
      state.fee = values.fee;
    },
    setCashPayment: (state, action) => {
      if (action.payload.credit) {
        state.qoutas = action.payload.quotas;
      } else {
        state.qoutas = 0;
      }
      state.cashPayment = !action.payload.credit;
    },
    setQuota: (state, action) => {
      state.qoutas = action.payload;
    },
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    setSaving: (state, action) => {
      state.saving = action.payload;
    },
    setTransaction: (state, action) => {
      state.transaction = action.payload;
    },
  },
});

export const { actions } = shoppingCartSlice;

export default shoppingCartSlice;

export const saveItem =
  (item: ShoppingCartItem) => async (dispatch: Dispatch<unknown>) => {
    const repository = new ShoppingCartRepository();
    const canAdd = repository.canAddFromStore(item.item.store_id.id);
    if (!canAdd) {
      toast.info(i18n.t("shoppingCart.cannot_add_message"));
      return;
    }
    const added = repository.add(item);
    if (!added) {
      const alreadyAdded = i18n.t("shoppingCart.already_added");
      toast.info(`${item.item.title} ${alreadyAdded}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      return;
    }
    toast.success(i18n.t("shoppingCart.item_added"), {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1500,
    });
    dispatch(actions.setItems(repository.all()));
  };

export const updateAmount =
  (index: number, amount: number) => async (dispatch: Dispatch<unknown>) => {
    const repository = new ShoppingCartRepository();
    repository.updateAmount(index, amount);
    dispatch(actions.setItems(repository.all()));
  };

export const loadSavedItems = () => async (dispatch: Dispatch<unknown>) => {
  const repository = new ShoppingCartRepository();
  const items = await repository.all();
  dispatch(actions.setItems(items));
};

export const updateItem =
  (item: ShoppingCartItem, index: number) =>
  async (dispatch: Dispatch<unknown>) => {
    const repository = new ShoppingCartRepository();
    await repository.update(index, item);
    dispatch(actions.setItems(repository.all()));
  };

export const removeItem =
  (index: number) => async (dispatch: Dispatch<unknown>) => {
    const repository = new ShoppingCartRepository();
    await repository.remove(index);
    toast.success(i18n.t("shoppingCart.item_remove"), {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1500,
    });
    dispatch(actions.setItems(repository.all()));
  };

export const loadPaymentMethods = () => async (dispatch: Dispatch<unknown>) => {
  const repository = new PaymentMethodRepository();
  const response = await repository.list({});
  if (response.status === 200) {
    const items: any[] = response.data.sort((left: any, rigth: any) =>
      left.id < rigth.id ? -1 : 1
    );
    dispatch(actions.setPaymentMethods(items));
  }
};

export const registerPaymentMethod =
  (data: any) => async (dispatch: Dispatch<unknown>) => {
    const repository = new PaymentMethodRepository();
    const response = await repository.register(data);
    if (response.status === 201) {
      dispatch(loadPaymentMethods());
    }
  };

export const pay = (data: any) => async (dispatch: Dispatch<unknown>) => {
  dispatch(actions.setSaving(true));
  const repository = new OrderRepository();
  const response = await repository.register({
    order: {
      credit_card_info_id: data.methodId,
      orderDetail: data.items,
    },
  });
  if (response.status === 201) {
    const order = response.data;
    if (order.order?.id) {
      const paymentRepository = new PaymentMethodRepository();

      const paymentResponse = await paymentRepository.pay({
        order_id: order.order.id,
      });

      if (paymentResponse.status === 201) {
        dispatch(actions.setTransaction(paymentResponse.data));
        const shoppingCart = new ShoppingCartRepository();
        shoppingCart.removeAll();
        dispatch(actions.setItems([]));
        dispatch(actions.setCashPayment({ credit: false }));
      }
    }
  }
  dispatch(actions.setSaving(false));
};

export const resetShopping = () => async (dispatch: Dispatch<unknown>) => {
  dispatch(actions.setTransaction(null));
};
