import { AxiosResponse } from 'axios';
import { httpClient } from 'application/utils/http-client';

export class OrderRepository {
  endpoint = '/order';

  async list(params: any): Promise<AxiosResponse> {
    const response = await httpClient.get(
      `${this.endpoint}`,
      { params },
    );

    return response;
  }

  async register(data: any): Promise<AxiosResponse> {
    const response = await httpClient.post(
      `${this.endpoint}`,
      data,
    );

    return response;
  }
}

export default OrderRepository;
