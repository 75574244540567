export const getImageUrl = (fileName: string) => {
  return `${process.env.REACT_APP_IMAGE_URL}/packages_images/${fileName}`;
};

export class I18nStorage {
  readonly key = "I18N_LANG";

  save(lang: string) {
    localStorage.setItem(this.key, lang);
  }

  load(): string | null {
    return localStorage.getItem(this.key) || "es";
  }
}
