import React from 'react';
import { useTranslation } from 'react-i18next';

export function EmptyShoppingCart() {
  const { t } = useTranslation();

  return (
    <div className="card well">
      <div className="card-body">
        <h5 className="card-title">{t("shoppingCart.empty")}</h5>
        <div className="mt-4 text-center">
          <img src="/assets/emptycart.jpeg" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  );
}
