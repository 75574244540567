import { Store } from "./store";

export class TripType {
    static readonly package = 1;

    static readonly rate = 2;
}
export interface Trip {
    id: number;
    title: string;
    subtitle: string;
    description: string;
    price: number;
    package_image: string;
    package_type: number;
    store_id: Store;
    qty_fee: number;
    adult_price: number;
    children_price: number;
    breakfast: number;
    date_end: Date;
    date_start: Date;
    full_meal: number;
    language: number;
    status: number;
}

export default Trip;
