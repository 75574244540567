import languages from "core/lang";
import { I18nStorage } from "core/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

export function DropdownLanguage() {
    const {i18n} = useTranslation();
    const [selectedLang, setSelectedLang] = useState("");
    const [menu, setMenu] = useState(false);

    const storage = new I18nStorage();
  
    useEffect(() => {
      const currentLanguage = storage.load() || "es";
      setSelectedLang(currentLanguage);
    }, []);
  
    const changeLanguageAction = (lang: string) => {
      i18n.changeLanguage(lang);
      storage.save(lang);
      setSelectedLang(lang);
    }
  
    const toggle = () => {
      setMenu(!menu);
    }

    const currentLang = languages[selectedLang];
    return (
        <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
          <img
            src={currentLang?.flag}
            alt={currentLang?.label}
            height="16"
            className="me-1"
          />
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {Object.keys(languages).map(key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"
                }`}
            >
              <img
                src={languages[key]?.flag}
                alt={currentLang?.label}
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {languages[key].label}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
    )
}