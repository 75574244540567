import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppStore } from 'domain/types/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
} from 'react-router-dom';

import './index.css';

import { authActions, uiActions } from 'application/store';
import SidebarContent from './Item';

export function DrawerLayout() {
  const dispatch = useDispatch();
  const user = useSelector((state: AppStore) => state.auth.user);
  const showMenu = useSelector((state: AppStore) => state.ui.sidebarOpen);
  const items = useSelector((state: AppStore) => state.shoppingCart.items);

  const activeClass = showMenu ? 'active-sidebar' : '';

  const handleLogOut = () => {
    dispatch(authActions.logOut());
  };

  const handleToggleMenu = () => {
    dispatch(uiActions.toggleSidebar());
  };

  return (
    <div className={`vertical-menu bg-dark ${activeClass}`}>
      <div className="text-right">
        <button
          type="button"
          className="btn btn-md text-white d-lg-none"
          onClick={handleToggleMenu}
        >
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      <div className="w-100 text-center">
        <Link to="/">
          <img src="/assets/logoenjoyhotels.png" alt="" />
          <h4 className="text-white mt-3">Mini Vacaciones</h4>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        <SidebarContent user={user} onLogOut={handleLogOut} cartItems={items.length} />
      </div>
      <div className="sidebar-background" />
    </div>
  );
}

export default DrawerLayout;
