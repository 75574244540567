import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DropdownLanguage, Fallback } from 'views/components';
import { DrawerLayout } from 'views/layouts';
import { routeMap } from 'views/pages/routes';

import './index.css';

const Home = lazy(() => import('views/pages/home'));
const Rates = lazy(() => import('views/pages/rates'));
const RateDetailPage = lazy(() => import('views/pages/rates/Detail'));
const RateListPage = lazy(() => import('views/pages/rates/List'));
const ShoppingCartPage = lazy(() => import('views/pages/shoppingCart'));
const ShoppingCartResumePage = lazy(() => import('views/pages/shoppingCart/Resume'));
const PaymentPage = lazy(() => import('views/pages/shoppingCart/Payment'));
const ProcessPayment = lazy(() => import('views/pages/shoppingCart/ProcessPayment'));


export default function MainPage() {

  return (
    <>
      <DrawerLayout />
      <div className="main-content px-2">
        <Suspense fallback={<Fallback />}>
          <div className='toggle-language'>
            <DropdownLanguage />
          </div>
          <Routes>
            <Route path={routeMap.home} element={<Home />} />
            <Route path={routeMap.rates} element={<Rates />}>
              <Route path=":id/details" element={<RateDetailPage />} />
              <Route index element={<RateListPage />} />
            </Route>
            <Route path={routeMap.shoppingCart} element={<ShoppingCartPage />}>
              <Route index element={<ShoppingCartResumePage />} />
              <Route path="payment" element={<PaymentPage />} />
              <Route path="process" element={<ProcessPayment />} />
            </Route>
            <Route path="/" element={<Navigate to={routeMap.rates} />} />
          </Routes>
        </Suspense>
      </div>
    </>

  );
}
