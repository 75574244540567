import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserLarge, faStar, faShoppingCart, faSignOut,
} from '@fortawesome/free-solid-svg-icons';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

import { NavLink } from 'react-router-dom';

import { routeMap } from 'views/pages/routes';
import { AuthUserDto } from 'core/dtos/auth';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export interface SidebarContentProps {
  user?: AuthUserDto;
  onLogOut(): void;
  cartItems?: number;
}

export function SidebarContent(props: SidebarContentProps) {
  const { user, onLogOut, cartItems } = props;
  const { t } = useTranslation();
  return (
    <SimpleBar className="h-100">
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled">
          <li>
            <hr />
            {
              user ? <h5 className="text-white text-center">{user.full_name}</h5> : (
                <NavLink to={routeMap.login} className="">
                  <h5 className="text-white">
                    <FontAwesomeIcon icon={faUserLarge} className="mx-1" />
                    {' '}
                    {t("drawer.item.login")}
                  </h5>
                </NavLink>
              )
            }
            <hr />
          </li>
          <li>
            <NavLink to={routeMap.rates} className="">
              <h5 className="text-white">
                <FontAwesomeIcon icon={faStar} className="mx-1" />
                <span>{t("drawer.item.packages")}</span>
              </h5>

            </NavLink>
          </li>
          <li>
            <NavLink to={routeMap.shoppingCart} className="">
              <h5 className="text-white">
                <FontAwesomeIcon icon={faShoppingCart} className="" />
                <span className="mx-1">
                  {t("drawer.item.shoppingCart")}
                  {
                  cartItems! > 0 ? (
                    <Badge color="primary" className="budget">
                      {cartItems}
                    </Badge>
                  ) : null
                }

                </span>
              </h5>
            </NavLink>
          </li>
          {
            user ? (
              <li className="text-center">
                <button type="button" className="btn" onClick={onLogOut}>
                  <h5 className="text-white">
                    <FontAwesomeIcon icon={faSignOut} className="mx-1" />
                    <span>{t("drawer.item.logout")}</span>
                  </h5>
                </button>
              </li>
            ) : null
          }

        </ul>
      </div>
    </SimpleBar>
  );
}

SidebarContent.defaultProps = {
  cartItems: 0,
};

export default SidebarContent;
