import { calculateRate } from "application/utils/shoppingCart";
import { RateItemCart, ShoppingCartItem } from "domain/types/store";

export class ShoppingCartRepository {
    private readonly store_key = "__shopping_cart__";

    private laodStorage = (): ShoppingCartItem[] => {
        const rawData = localStorage.getItem(this.store_key);
        if (rawData) {
            return JSON.parse(rawData) as ShoppingCartItem[];
        }
        return [];
    };

    private saveStore = (data: ShoppingCartItem[]) => {
        localStorage.setItem(this.store_key, JSON.stringify(data));
    };

    canAddFromStore(storeId: number) {
        const data = this.laodStorage();
        if (!data) {
            return true;
        }

        return data.filter((cart) => cart.item.store_id.id === storeId);
    }

    add(trip: ShoppingCartItem): boolean {
        const data = this.laodStorage();
        const item = data.find((row) => row.item.id === trip.item.id);
        if (item) {
            return false;
        }

        data.push(trip);
        this.saveStore(data);

        return true;
    }

    updateAmount(index: number, amount: number) {
        const data = this.laodStorage();
        const item: any = data[index];
        item.amount = amount;
        if (item.item) {
            item.total = calculateRate(item.item as RateItemCart, item.amount);
        } else {
            item.total = item.amount * item.item.price;
        }
        data[index] = item;
        this.saveStore(data);
    }

    update(index: number, item: ShoppingCartItem) {
        const data = this.laodStorage();
        data[index] = item;
        this.saveStore(data);
    }

    remove(index: number) {
        const items = this.laodStorage().filter((item, i) => index !== i);
        this.saveStore(items);
    }

    all(): ShoppingCartItem[] {
        return this.laodStorage();
    }

    removeAll() {
        this.saveStore([]);
    }
}
