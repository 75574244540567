import React from 'react';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from 'reactstrap';

export interface UserCardProps {
    user: string;
    identification: string;
    registered: boolean;
}

export function UserCard(props: UserCardProps) {
  const { user, identification, registered } = props;
  const {t} = useTranslation();

  return (
    <div>
      <Card>
        <CardBody>
          <CardHeader className="d-flex justify-content-start align-items-center">
            <div className="mr-2">
              <FontAwesomeIcon size="6x" icon={faUserCircle} />
            </div>
            <div className="mx-2">
              <h4>{user}</h4>
              <h5 className="text-muted">{identification}</h5>
              {
                  registered ? (
                    <div className="rounded shadow bg-warning p-1 text-center text-white">
                      <strong>{t("userCard.registered.label")}</strong>
                    </div>
                  ) : null
              }
            </div>
          </CardHeader>
        </CardBody>
      </Card>
    </div>
  );
}
