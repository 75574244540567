import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from 'application/store';
// eslint-disable-next-line no-unused-vars
import $ from 'jquery';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';

console.log(i18n);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

reportWebVitals();
