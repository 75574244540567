import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import es from "./langs/es.json";
import en from "./langs/en.json";

import { I18nStorage } from "core/utils";

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};

const storage = new I18nStorage();

const language = storage.load();
if (!language) {
  storage.save("es");
}

i18n.use(initReactI18next).init({
  resources,
  lng: storage.load() || "es",
  fallbackLng: "es",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
