import { AuthRepository } from 'application/repositories';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpClient.interceptors.response.use(undefined, async (error: AxiosError) => {
  const statusCode = error.response?.status;

  if (statusCode === 401) {
    toast.info('Tu sesion ha expirado');
    const repository = new AuthRepository();
    repository.logOut();
    window.location.href = '/login';
    // const token = repository.getRefreshToken();
    // if (token) {
    //   const authResponse = await httpClient.post('/auth/login/refresh', { refreshToken: token });
    //   const request = error.config;
    //   if (authResponse.status === 200 || authResponse.status === 201) {
    //     const data = authResponse.data as {token: string};
    //     repository.setToken(data.token);
    //     request.headers!.Authorization = `Bearer ${data.token}`;
    //     return httpClient(request);
    //   }
    // }
  }
  return error;
});

httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const repository = new AuthRepository();
  const token = repository.getToken();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config!.headers!.Authorization = `Bearer ${token}`;
  }

  return config;
});
