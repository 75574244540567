import React from 'react';

export class RateErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch() {

  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <h4>
          Algo salio mal:
          {' '}
          {error?.message}
        </h4>
      );
    }
    return children;
  }
}
