/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Alert,
  Card, CardBody, Col, FormFeedback, Input, Label, Row, //  Alert, Col,
} from 'reactstrap';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import profile from 'assets/images/profile-img.png';
import logo from 'assets/images/logo.png';
import { AuthRepository } from 'application/repositories';
import { AuthDto } from 'core/dtos/auth';
import { useTranslation } from 'react-i18next';

export interface LoginFormProps {
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onUserLogin(data: AuthDto): void;
  compact?: boolean;
}

interface Auth {
  username: string;
  password: string;
}

export function LoginForm(props: LoginFormProps) {
  const { handleSubmit, formState: { errors }, control } = useForm<Auth>();
  const [isLoging, setIsLoging] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();
  const { className, onUserLogin, compact } = props;

  const handleLogin = async (data: Auth) => {
    setIsLoging(true);
    setError('');
    const service = new AuthRepository();
    try {
      const response = await service.login(data.username, data.password);
      if (response?.status === 201) {
        setIsLoging(false);
        onUserLogin(response.data);
      } else {
        setError(response.data.toString());
      }
    } catch (exception) {
      setError(t("login.message.invalid-credentials"));
    }
    setIsLoging(false);
  };

  return (
    <Card className={className}>
      {
        compact ? null : (
          <div className="bg-dark">
            <Row>
              <Col xs={7}>
                <div className="text-primary p-4">
                  <h5 className="text-white">{t("login.session.start")}</h5>
                  <p className="text-white">{t('login.access.minivaction')}</p>
                </div>
              </Col>
              <Col className="col-5 align-self-end">
                <img src={profile} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
        )
      }

      <CardBody className="pt-0">
        {
          compact ? null : (
            <div className="auth-logo-light">
              <div className="avatar-md profile-user-wid mb-4">
                <span className="avatar-title rounded-circle bg-light">
                  <img
                    src={logo}
                    alt=""
                    className="rounded-circle"
                    height="34"
                  />
                </span>
              </div>
            </div>
          )
        }
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="mt-3">
            <p className="text-muted" style={{ fontStyle: 'italic' }}>
              {t('login.text.message')}
            </p>
          </div>
          <div className="mb-3">
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <Label className="form-label">{t('login.input.email')}</Label>
                  <Input
                    className="form-control"
                    placeholder="Email"
                    type="email"
                    invalid={!!errors.username}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                  {errors.username && errors.username?.type === 'required' ? (
                    <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                  ) : null}
                </>
              )}
            />

          </div>
          <div className="mb-3">
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <Label className="form-label">{t('login.input.password')}</Label>
                  <Input
                    className="form-control"
                    placeholder="Contrasena"
                    type="password"
                    invalid={!!errors.password}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                  {errors.password && errors.password?.type === 'required' ? (
                    <FormFeedback type="invalid">{t("form.required.field")}</FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="customControlInline"
            />
            <label
              className="form-check-label"
              htmlFor="customControlInline"
            >
              {t("login.label.remember")}
            </label>
          </div>
          <div className="mt-3 d-grid">
            <button
              disabled={isLoging}
              className="btn btn-primary btn-block text-white"
              type="submit"
            >
              {t("login.session.start")}
            </button>
          </div>
          {
            error ? (
              <div className="mt-3 d-grid">
                <Alert color="danger" fade>
                  <span className="text-white">{error}</span>
                </Alert>
              </div>
            ) : null
          }
          <div className="mt-4 text-center">
            <Link to="." className="text-muted">
              <FontAwesomeIcon icon={faLock} />
              {' '}
              {t("login.label.forgot.password")}
            </Link>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}

LoginForm.defaultProps = {
  className: 'shadow',
  compact: false,
};
