/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { AuthRepository } from 'application/repositories';
import { AuthStore } from 'domain/types/store';

const initialState: AuthStore = {
  user: undefined,
  isLoggin: false,
};

const repository = new AuthRepository();

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn: () => {

    },
    logOut(state) {
      repository.logOut();
      state.user = undefined;
    },
    logInFromStorage: (state) => {
      const user = repository.getStoreduser();
      state.user = user;
    },
  },
});

export const { actions } = authSlice;

export default authSlice;
